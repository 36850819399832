import axios from 'axios';
import { PnPClientStorage } from "@pnp/common";
const storage = new PnPClientStorage();
// const APP_ID = '375ea6b4-d46d-40df-aff2-7931f5eaf5a3';
// const APP_SECERET = 'jyS2PwPK8OdgVG5N1e_1Q1j~Ni5-h-Ufb5';
// const APP_ID = 'a86c7422-c0c9-4b83-ab15-cbcbee644b5f';
// const APP_SECERET = 'kb-r5XA9r6uVaqpe8xJO~_7B11-5JDc_s0';
// const TOKEN_ENDPOINT = 'https://login.microsoftonline.com/8aa07f6a-735f-49f8-80f5-95eaf445c393/oauth2/v2.0/token';
// const MS_GRAPH_SCOPE = 'https://wvib.de/wvibpublicweb/.default';
const FUNCTIONCODE = 'zaMLx7LLYUARNGgWY8SxBoPvWB9kK761kcOsDoKb9e7gPIJc942EOA==';
const FUNCTIONCODETest = 'XnYiCjfXAr3SLVtUDaFen/k5UYolbU/DXS5BHfj83KkihsNroRwopA==';
const FUNCTIONId = "publicweb";
const FUNCTIONIdTest = "nav_publicweb";

 
const azureFuncUrl = 'https://wvibpublicwebprod.azurewebsites.net/api';
const azureFuncUrlTest = 'https://wvibpublicwebtest.azurewebsites.net/api';
//const {azureFuncUrl,FUNCTIONCODE} = process.env;

// const qs = require('qs');
export default class AzureConnector {

    private storageNews: string = 'storageNews';
    private storageContacts: string = 'storageContacts';
    private storageStaticpages: string = 'staticpages';
    private storageThemen: string = 'storageThemen';
    private storageEvents: string = 'storageEvents';
    private storageDocuments: string = 'storageDocuments';
    private storageConfig: string = 'storageConfig';
    private storageCommunties: string = 'storageCommunties';
    private storageTerms: string = 'terms';


    azureInstance: any;
    public getData(): Promise<void> {
        return new Promise<void>((resolve: (result: any) => void, reject: (error: any) => void): void => {
            //#region Get Contacts-Data Section
            let config = {
                headers: {
                    Accept: "application/json, text/plain, */*",
                    'Content-Type': "application/json; charset=utf-8",
                    'x-functions-key': FUNCTIONCODE,
                    'x-functions-id': "publicweb"
                }
            };
            
            let currAzureFuncUrl = azureFuncUrl + '/sp/contacts';
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                if (res.data.length > 0) {
                    let currArray: any[] = [];
                    res.data.forEach((element: any) => {
                       currArray.push(element);
                    });
                    storage.session.put(this.storageContacts, currArray);
                    resolve(currArray);
                } else {
                    reject("no data");
                }
                })
                .catch((error: any) => {
                    //console.log(error);
                    reject(error);
                });

            //#endregion
            //#region Document-Data Section
            currAzureFuncUrl = azureFuncUrl + '/sp/documents';
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                if (res.data.length > 0) {
                    let currArray: any[] = [];
                    res.data.forEach((element: any) => {
                        currArray.push(element);
                    });
                    storage.session.put(this.storageDocuments, currArray);
                    resolve(res.data);
                } else {
                    reject("no data");
                }
            })
                .catch((error: any) => {
                    //console.log(error);
                    reject(error);
                });
            //#endregion
             //#region Communities-Data Section
             currAzureFuncUrl = azureFuncUrl + '/sp/links';
             axios.get(currAzureFuncUrl, config).then((res: any): void => {
                 if (res.data.length > 0) {
                     let currArray: any[] = [];
                     res.data.forEach((element: any) => {
                         currArray.push(element);
                     });
                     storage.session.put(this.storageCommunties, currArray);
                     resolve(res.data);
                 } else {
                     reject("no data");
                 }
             })
                 .catch((error: any) => {
                     //console.log(error);
                     reject(error);
                 });
             //#endregion
            //#region StartseiteConfig-Data Section

                //TODO: replace by prod.
                let configTest = {
                    headers: {
                        Accept: "application/json, text/plain, */*",
                        'Content-Type': "application/json; charset=utf-8",
                        'x-functions-key': FUNCTIONCODETest,
                        'x-functions-id': FUNCTIONIdTest
                    }
                };
                
            currAzureFuncUrl = azureFuncUrl + '/sp/startpageconfigs';
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                if (res.data.length > 0) {
                    let currArray: any[] = [];
                    res.data.forEach((element: any) => {
                        currArray.push(element);
                    });
                    storage.session.put(this.storageConfig, currArray);
                    resolve(res.data);
                } else {
                    reject("no data");
                }
            })
                .catch((error: any) => {
                    //console.log(error);
                    reject(error);
                });
            //#endregion
            //#region get Themen-Data Section

            currAzureFuncUrl = azureFuncUrl + '/sp/themes';
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                if (res.data.length > 0) {
                    let currArray: any[] = [];
                    res.data.forEach((element: any) => {
                        currArray.push(element);
                    });
                    storage.session.put(this.storageThemen, currArray);
                    resolve(res.data);
                } else {
                    reject("no data");
                }
            })
                .catch((error: any) => {
                    //console.log(error);
                    reject(error);
                });
            //#endregion
            //#region get static Page

           
            currAzureFuncUrl = azureFuncUrl + '/sp/staticpages';
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                if (res.data.length > 0) {
                    let currArray: any[] = [];
                    res.data.forEach((element: any) => {
                        currArray.push(element);
});
                    storage.session.put(this.storageStaticpages, currArray);
                    resolve(res.data);
                } else {
                    reject("no data");
                }
            })
                .catch((error: any) => {
                    //console.log(error);
                    reject(error);
                });

            //#endregion
            //#region get Events Section

            // currAzureFuncUrl = azureFuncUrl + "/nav/events?filter=(Start_date ge datetime'2020-01-20')";
            // axios.get(currAzureFuncUrl, config).then((res: any): void => {
            //     if (res.data.length > 0) {
            //         let currArray: any[] = [];
            //         res.data.forEach((element: any) => {
            //             currArray.push(element);
            //         });
            //         if (currArray.length > 0) {
            //             //console.log("event", currArray);
            //             currArray = currArray.sort((a, b) => new Date(b.Start_date).getTime() - new Date(a.Start_date).getTime());
            //             storage.session.put(this.storageEvents, currArray);
            //         }

            //         resolve(res.data);
            //     } else {
            //         reject("no data");
            //     }
            // })
            //     .catch((error: any) => {
            //         //console.log(error);
            //         reject(error);
            //     });
            //#endregion
        });
    }
    //#region get Terms
    public getEvents(filter: any): Promise<void> {

        return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
            let config = {
                headers: {
                    // Authorization: "Bearer " + this.auth_token,
                    Accept: "application/json, text/plain, */*",
                    'Content-Type': "application/json; charset=utf-8",
                    'x-functions-key': FUNCTIONCODE,
                    'x-functions-id': "publicweb"
                }
            };
            let currAzureFuncUrl = azureFuncUrl + '/nav/' + filter;
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                let currArray: any[] = [];
                if (res.data.length > 0) {
                    res.data.forEach((element: any) => {
                        currArray.push(element);
                    });
                    //console.log("in Event");
                    resolve(res.data);
                } else {
                    resolve(currArray);
                }
            }).catch((error: any) => {
                //console.log(error);
                reject(error);
            });

        });

    }
    //#endregion
     //#region get Terms
     public getTestTerms(): Promise<void> {
        return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
            let config = {
                headers: {
                    // Authorization: "Bearer " + this.auth_token,
                    Accept: "application/json, text/plain, */*",
                    'Content-Type': "application/json; charset=utf-8",
                    'x-functions-key': FUNCTIONCODETest,
                    'x-functions-id': FUNCTIONIdTest
                }
            };
            let _azureFuncUrl = "https://wvibpublicwebtest.azurewebsites.net/api";
            let currAzureFuncUrl = _azureFuncUrl + '/sp/terms';
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                let currArray: any[] = [];
                if (res.data.length > 0) {
                    res.data.forEach((element: any) => {
                        currArray.push(element);
                    });
                    resolve(res.data);
                } else {
                    resolve(currArray);
                }
            }).catch((error: any) => {
                //console.log(error);
                reject(error);
            });

        });

    }
    //#region get Terms
    public getTerms(): Promise<void> {
        return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
            let config = {
                headers: {
                    // Authorization: "Bearer " + this.auth_token,
                    Accept: "application/json, text/plain, */*",
                    'Content-Type': "application/json; charset=utf-8",
                    'x-functions-key': FUNCTIONCODE,
                    'x-functions-id': "publicweb"
                }
            };
            let currAzureFuncUrl = azureFuncUrl + '/sp/terms';
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                let currArray: any[] = [];
                if (res.data.length > 0) {
                    res.data.forEach((element: any) => {
                        currArray.push(element);
                    });
                    resolve(res.data);
                } else {
                    resolve(currArray);
                }
            }).catch((error: any) => {
                //console.log(error);
                reject(error);
            });

        });

    }
    //#endregion
    //#region get Contacts
    public getContacts(): Promise<void> {

        return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
            let config = {
                headers: {
                    // Authorization: "Bearer " + this.auth_token,
                    Accept: "application/json, text/plain, */*",
                    'Content-Type': "application/json; charset=utf-8",
                    'x-functions-key': FUNCTIONCODE,
                    'x-functions-id': "publicweb"
                }
            };
            let currAzureFuncUrl = azureFuncUrl + '/sp/contacts';
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                let currArray: any[] = [];
                if (res.data.length > 0) {
                    res.data.forEach((element: any) => {
                        currArray.push(element);
                    });
                    // //console.log(currArray);
                    // currArray=currArray.sort((a, b) => (a.ranking) - (b.ranking));
                    // //console.log("after sort",currArray);
                    resolve(currArray);
                } else {
                    resolve(currArray);
                }
            }).catch((error: any) => {
                //console.log(error);
                reject(error);
            });

        });

    }
    //#endregion
    //#region get Themen Images
    public getThemenImages(): Promise<void> {

        return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
            let config = {
                headers: {
                    // Authorization: "Bearer " + this.auth_token,
                    Accept: "application/json, text/plain, */*",
                    'Content-Type': "application/json; charset=utf-8",
                    'x-functions-key': FUNCTIONCODE,
                    'x-functions-id': "publicweb"
                }
            };
            let currAzureFuncUrl = azureFuncUrl + '/sp/themes';
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                let currArray: any[] = [];
                if (res.data.length > 0) {
                    resolve(res.data);
                } else {
                    resolve(currArray);
                }
            }).catch((error: any) => {
                //console.log(error);
                reject(error);
            });

        });

    }
    //#endregion
    //#region communities List
    public getCommunitiesList(): Promise<void> {

        return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
            let config = {
                headers: {
                    // Authorization: "Bearer " + this.auth_token,
                    Accept: "application/json, text/plain, */*",
                    'Content-Type': "application/json; charset=utf-8",
                    'x-functions-key': FUNCTIONCODE,
                    'x-functions-id': "publicweb"
                }
            };
            let currAzureFuncUrl = azureFuncUrl + '/sp/links';
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                let currArray: any[] = [];
                if (res.data.length > 0) {
                    resolve(res.data);
                } else {
                    resolve(currArray);
                }
            }).catch((error: any) => {
                //console.log(error);
                reject(error);
            });

        });

    }
    //#endregion
    public getSearchNews(): Promise<void> {

        return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
            //#region  get News-Data Section
            let config = {
                headers: {
                    // Authorization: "Bearer " + this.auth_token,
                    Accept: "application/json, text/plain, */*",
                    'Content-Type': "application/json; charset=utf-8",
                    'x-functions-key': FUNCTIONCODE,
                    'x-functions-id': "publicweb"
                }
            };
            let currAzureFuncUrl = azureFuncUrl + '/sp/search/news';
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                let currArray: any[] = [];
                if (res.data.length > 0) {
                    resolve(res.data);
                } else {
                    resolve(currArray);
                }
            }).catch((error: any) => {
                //console.log(error);
                reject(error);
            });
            //#endregion
        });

    }
    public getNews(): Promise<void> {

        return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
            //#region  get News-Data Section
            let config = {
                headers: {
                    // Authorization: "Bearer " + this.auth_token,
                    Accept: "application/json, text/plain, */*",
                    'Content-Type': "application/json; charset=utf-8",
                    'x-functions-key': FUNCTIONCODE,
                    'x-functions-id': "publicweb"
                }
            };
            let currAzureFuncUrl = azureFuncUrl + '/sp/news';
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                let currArray: any[] = [];
                if (res.data.length > 0) {
                    resolve(res.data);
                } else {
                    resolve(currArray);
                }
            }).catch((error: any) => {
                //console.log(error);
                reject(error);
            });
            //#endregion
        });

    }
    public getDocs(): Promise<void> {

        return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
            //#region  get News-Data Section
            let config = {
                headers: {
                    // Authorization: "Bearer " + this.auth_token,
                    Accept: "application/json, text/plain, */*",
                    'Content-Type': "application/json; charset=utf-8",
                    'x-functions-key': FUNCTIONCODE,
                    'x-functions-id': "publicweb"
                }
            };
            let currAzureFuncUrl = azureFuncUrl + '/sp/documents';
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                let currArray: any[] = [];
                if (res.data.length > 0) {
                    resolve(res.data);
                } else {
                    resolve(currArray);
                }
            }).catch((error: any) => {
                //console.log(error);
                reject(error);
            });
            //#endregion
        });

    }
    public getTermNews(newsId: string) {
        return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
            //#region  get News-Data Section
            let config = {
                headers: {
                    // Authorization: "Bearer " + this.auth_token,
                    Accept: "application/json, text/plain, */*",
                    'Content-Type': "application/json; charset=utf-8",
                    'x-functions-key': FUNCTIONCODE,
                    'x-functions-id': "publicweb"
                }
            };
            let currAzureFuncUrl = azureFuncUrl + '/sp/news';
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                let currArray: any[] = [];
                if (res.data.length > 0) {
                    res.data.forEach((element: any) => {
                        if (element != null && element.term.length > 0) {
                            element.term.forEach((e1: any) => {
                                if (e1.termName === newsId) {
                                    currArray.push(element)
                                }
                            });
                        }
                    });

                    resolve(currArray);
                } else {
                    resolve(currArray);
                }
            }).catch((error: any) => {
                //console.log(error);
                reject(error);
            });
            //#endregion
        });
    }
    public getCurrentNews(newsId: string) {
        return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
            //#region  get News-Data Section
            let config = {
                headers: {
                    // Authorization: "Bearer " + this.auth_token,
                    Accept: "application/json, text/plain, */*",
                    'Content-Type': "application/json; charset=utf-8",
                    'x-functions-key': FUNCTIONCODE,
                    'x-functions-id': "publicweb"
                }
            };
            let currAzureFuncUrl = azureFuncUrl + '/sp/news/' + newsId;
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                let currArray: any[] = [];
                if (res.data !== null) {
                    currArray.push(res.data)
                    resolve(currArray);
                } else {
                    resolve(currArray);
                }
            }).catch((error: any) => {
                //console.log(error);
                reject(error);
            });
            //#endregion
        });
    }
    //#region StartseiteConfig-Data Section

    public getTermConfigListPages(): Promise<void> {
        return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
            //#region  get News-Data Section
            let config = {
                headers: {
                    // Authorization: "Bearer " + this.auth_token,
                    Accept: "application/json, text/plain, */*",
                    'Content-Type': "application/json; charset=utf-8",
                    'x-functions-key': FUNCTIONCODETest,
                    'x-functions-id': FUNCTIONIdTest
                }
            };
            let currAzureFuncUrl = azureFuncUrl + '/sp/startpageconfigs';
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                let currArray: any[] = [];
                if (res.data.length > 0) {
                    resolve(res.data);
                } else {
                    resolve(currArray);
                }
            })
                .catch((error: any) => {
                    //console.log(error);
                    reject(error);
                });
            //#endregion
        });
    }
    //#endregion
    public getStaticPages(): Promise<void> {

        return new Promise<void>(async (resolve: (result: any) => void, reject: (err: any) => void): Promise<void> => {
            //#region  get News-Data Section
            let config = {
                headers: {
                    // Authorization: "Bearer " + this.auth_token,
                    Accept: "application/json, text/plain, */*",
                    'Content-Type': "application/json; charset=utf-8",
                    'x-functions-key': FUNCTIONCODE,
                    'x-functions-id': "publicweb"
                }
            };
            let currAzureFuncUrl = azureFuncUrl + '/sp/staticpages';
            axios.get(currAzureFuncUrl, config).then((res: any): void => {
                let currArray: any[] = [];
                if (res.data.length > 0) {
                    resolve(res.data);
                } else {
                    resolve(currArray);
                }
            }).catch((error: any) => {
                //console.log(error);
                reject(error);
            });
            //#endregion
        });

    }
}
